.packs {
  $text-color: #4a4a4a;
  color: $text-color;
  padding-top: 56px;
  padding-bottom: 64px;

  .container {
    max-width: 960px;
  }

  h4 {
    font-weight: 600;
    line-height: 20px;
    margin: 48px 0 32px;
    font-size: 20px;
  }

  &-operation {
    border-bottom: 1px solid rgba(#000, .1);
  }

  &-list {
    list-style: none;
    padding: 0;

    li {
      height: 36px;
      border-bottom: 1px solid rgba(#000, .1);

      a {
        color: $text-color;
        display: inline-block;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 8px;

        &:hover {
          text-decoration: none;
          background: rgba(#000, .03);
        }
      }
    }
  }

  &-placeholder {
    width: 100%;
    padding: 48px 32px;
    background: #eee;
    color: rgba($text-color, .6);
    text-align: center;
  }
}
