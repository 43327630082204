.gear-create-form {
  $primary: #007bff;

  .btn-default {
    line-height: 1.5;
    padding: 10px;
    width: 100%;
    text-align: left;
    border-radius: 0;
    font-size: 16px;
    height: 44px;
    font-size: 14px;
    color: $primary;

    &:hover {
      color: darken($primary, 10%);
      text-decoration: none;
      background: rgba($primary, .05);
    }

    svg {
      margin: 0 12px 0 5px;
    }
  }

  input {
    font-size: 14px;
    width: 100%;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid rgba(#000, .05);
    padding: 8px;
    height: auto;
    display: none;
    height: 44px;

    &:focus {
      outline: none;
      border-color: rgba(#000, .05);
      box-shadow: 0 0 0 2px rgba(#007bff, .5) inset;
    }

    &.shown {
      display: block;
    }
  }
}
