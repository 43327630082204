@import '../mixin/responsive.scss';

.landing  {
  --primary-color: #1e6cf9;
  --body-bg: #d7d8dc;
  --footer-bg: #565660;
  --text-color: #31323e;
  --light-blue: #6f9ff5;
  --gutter-width: 80px;
}

@include when-screen(lg) {
  .landing { --gutter-width: 40px; }
}

@include when-screen(md) {
  .landing { --gutter-width: 20px; }
}

.landing {
  background: var(--body-bg);
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--text-color);
}

.landing {
  h1 {
    font-size: 40px;
    line-height: 56px;
    font-weight: 500;
    margin-bottom: 32px;
    position: relative;

    @include when-screen(lg) {
      margin-bottom: 16px;
    }
  }

  h2 {
    display: inline-block;
    font-size: 36px;
    line-height: 48px;
    font-weight: 500;
    position: relative;
    margin-top: 96px;
    margin-bottom: 64px;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 18px;
      position: absolute;
      background: var(--light-blue);
      bottom: 2px;
      z-index: -1;
    }

    @include under-screen(md) {
      margin-top: 48px;
      margin-bottom: 32px;
    }
  }

  h4 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    color: var(--primary-color);
  }

  .lead {
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 24px;
    position: relative;

    @include when-screen(lg) {
      font-size: 16px;
    }
  }

  .lp-container {
    margin: 0 auto;
    height: 100%;

    @include when-screen(xs) {
      max-width: 100%;
      padding: 0 24px;
    }
    @include when-screen(sm) {
      max-width: 540px;
      padding: 0 24px;
    }
    @include when-screen(md) {
      max-width: 720px;
    }
    @include when-screen(lg) {
      max-width: 960px;
    }
    @include when-screen(xl) {
      max-width: 1140px;
    }
    @include when-screen(xxl) {
      max-width: 1320px;
    }
  }

  .lp-row {
    width: 100%;

  }

  /* Navbar */
  .lp-navbar {
    height: 100px;
    background: #fff;
    font-size: 34px;
    color: var(--primary-color);
    font-weight: 600;

    &__container {
      display: flex;
      align-items: center;
    }
  }


  /* Hero */
  .hero {
    width: 100%;
    color: #fff;
    padding-top: 88px;
    background-image: url('https://i.gyazo.com/4dff5f1725a755b3897f0dcf681053ae.jpg');
    background-size: cover;
    background-position: center;
    position: relative;

    &__overlay {
      position: absolute;
      background: #1e1e25;
      opacity: 0.6;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &__row {
      display: flex;
      height: 100%;

      @include under-screen(md) {
        flex-direction: column;
        text-align: center;
      }
    }

    &__left {
      @include when-screen(lg) {
        padding-right: 48px;
      }

      @include when-screen(xl) {
        padding-right: 96px;
      }

      @include when-screen(xxl) {
        padding-right: 96px;
      }

    }

    &__right {

    }

    &__img {
      width: 565px;
      background: #fff;
      position: relative;

      &:after {
        content: '';
        display:block;
        width: 5px;
        padding-top: 89.2%;
      }

      @include under-screen(md) {
        margin-top: 32px;
        width: 100%;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  /* Button Custome */
  .firebaseui-container {
    @include when-screen(lg) {
      margin: 0 !important;
    }

    @include when-screen(xl) {
      margin: 0 !important;
    }

    @include when-screen(xxl) {
      margin: 0 !important;
    }
  }

  .firebaseui-container > .firebaseui-card-content {
    padding: 0 !important;
  }

  .firebaseui-idp-list>.firebaseui-list-item {
    @include when-screen(lg) {
      text-align: left !important;
    }

    @include when-screen(xl) {
      text-align: left !important;
    }

    @include when-screen(xxl) {
      text-align: left !important;
    }
  }


  /* Features */
  .features {
    text-align: center;

    &__row {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      margin: 0 calc(var(--gutter-width) / -2);

      @include under-screen(sm) {
        margin: 0;
      }
    }

    &__col {
      flex: 1 1 33%;
      padding: 0 calc(var(--gutter-width) / 2);

      @include under-screen(sm) {
        flex-basis: 100%;
        margin-bottom: 40px;
        padding: 0;
      }
    }
  }

  .feature {
    height: 100%;
    background: #fff;
    padding: 48px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 4px solid var(--light-blue);

    &__title {
      margin-bottom: 32px;
    }

    &__image {
      width: 96px;
      height: 96px;
      margin-bottom: 32px;
      position: relative;
    }
  }

  /* Footer */
  footer {
    text-align: center;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--footer-bg);
    color: #fff;
    margin-top: 88px;

    @include when-screen(xs) {
      margin-top: 44px;
    }
  }
}
