.category-create-form {
  $primary: #007bff;
  margin-bottom: 16px;

  .btn-default {
    line-height: 1.5;
    padding: 10px;
    width: 100%;
    text-align: left;
    border-radius: 0;
    font-size: 14px;
    height: 44px;
    color: $primary;

    &:hover {
      text-decoration: none;
      color: darken($primary, 10%);
      background: rgba($primary, .05);
    }

    svg {
      margin: 0 12px 0 5px;
    }
  }

  input {
    font-size: 14px;
    width: 100%;
    border-radius: 0;
    border: 0;
    padding: 8px;
    height: 44px;

    &:focus {
      outline: none;
      border-color: rgba(#000, .05);
      box-shadow: 0 0 0 2px rgba(#007bff, .5) inset;
    }
  }
}
