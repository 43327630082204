.category-header {
  $row-height: 28px;
  $line-height: 20px;
  width: 100%;
  border: 1px solid #d8d8d8;
  border-bottom: none;
  background: #ededed;

  &.no-gears {
    border-bottom: 1px solid #d8d8d8;
  }

  &-row {
    width: 100%;
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 0 4px 0 12px;
    font-size: 14px;
    line-height: $line-height;

    &.shared {
      padding-right: 40px;

      @media (max-width: 576px) {
        padding-right: 32px;
      }
    }
  }

  &-name {
    flex: 1 0 auto;
    width: 0;
    padding: calc((#{$row-height} - #{$line-height}) / 2) 0;
    font-weight: 600;
  }

  &-weight {
    flex: 0 0 72px;
    height: $row-height;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 600;
  }

  &-btn {
    padding: 0;
    flex: 0 0 $row-height;
    height: $row-height;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 0;
    color: #4a4a4a;
    margin-right: 8px;

    &:hover {
      background: rgba(#000, .05);
      color: #4a4a4a;
    }

    .fa-edit {
      position: relative;
      left: 1px;
    }
  }

  &-placeholder {
    margin: 4px 12px 12px 12px;
    text-align: center;
    padding: 12px 0;
    opacity: .6;
    font-size: 14px;
  }
}
