.shared-pack {
  &-footer {
    font-size: 20px;
    height: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ededed;

    .logotype {
      font-weight: 600;
    }
  }
}
