@import './mixin/responsive';

.pack-wrapper { /* wrapper for side nav and pack container */
  display: flex;
  height: calc(100vh - 56px);
}

.pack-container {
  flex: 1;
  margin-top: 56px;
  height: calc(100vh - 56px);
  overflow: scroll;

  &-col {
    max-width: 760px;
    width: 100%;
    margin: 0 auto;
    padding: 56px 24px;

    @include when-screen(xs) {
      padding: 32px 24px;
    }
  }

  .pack-header {
    margin-bottom: 26px;
  }
}
