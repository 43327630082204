.navbar {
  .nav-link {
    cursor: pointer;
  }
}

.user-icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.navbar-dropdown {
  flex-grow: 1;
  text-align: right;
}

.dropdown-toggle.user-menu-toggle {
  &:after {
    content: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link-settings {
    padding-right: 20px;
    padding-left: 20px;
  }
}
