.pack-create-form {
  $primary: #007bff;

  .btn-create {
    width: 100%;
    text-align: left;
    border-radius: 0;
    color: $primary;

    svg {
      margin-right:  4px;
      position: relative;
      top: -1px;
    }

    &:hover {
      color: darken($primary, 10%);
      text-decoration: none;
      background: rgba($primary, .05);
    }
  }

  input {
    width: 100%;
    border-radius: 0;
  }

  .operations {
    margin-top: 8px;
    text-align: right;

    button {
      width: 110px;
    }
  }
}
