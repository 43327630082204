.pack-select-menu {
  max-height: 300px;
  overflow-y: scroll;

  &.selected {
    pointer-events: none;
  }

  svg {
    margin-left: 6px;
    fill: #4a4a4a;
  }
}
