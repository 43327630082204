.pack-category {
  $primary: #007bff;
  position: relative;
  margin-bottom: 16px;

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba($primary, .2);

    &.dragenter {
      background: rgba($primary, .35);
    }
  }
}

.draggable {
  position: relative;

  .draggable__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid black;
    display: none;

    &.show {
      display: block;
      background: rgba(0,0,0, 0.2);
    }

    &.upper {
      border-top-color: red;
      z-index: 10000;
    }

    &.bottom {
      border-bottom-color: red;
      z-index: 10000;
    }


  }
}
