@import './mixin/responsive.scss';

.pack-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 0;
  flex-direction: column;

  &-title {
    display: flex;
    align-items: center;
  }

  h2 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
    margin: 0;
    padding: 6px 0;

    @include when-screen(xs) {
      font-size: 21px;
    }
  }

  &-btn {
    width: 48px;
    height: 48px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    cursor: pointer;

    &:hover {
      background: rgba(#000, .05);
    }
  }

  &-operation {
    margin-top: 16px;
    
    @include when-screen(xs) {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }
  }
}

.weight-block {
  display: flex;
  font-size: 14px;
  line-height: 24px;
  margin-top: 16px;

  &-divider {
    width: calc(100% + 10px);
    height: 1px;
    background: #4a4a4a;
    margin: 4px 0;

    &-left {
      margin-right: -8px;
    }

    &-right {
      margin-left: -8px;
    }
  }

  &-values {
    margin-left: 16px;
    text-align: right;
  }

  &-title {
    &-sum {
      font-weight: 600;
    }
  }

  &-value {
    &-sum {
      font-weight: 600;
    }
  }
}
