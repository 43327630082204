.table-row {
  $row-height: 28px;
  $line-height: 20px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  border: 1px solid #d8d8d8;
  padding: 0 4px 0 10px;
  font-size: 14px;
  line-height: $line-height;

  &-name {
    flex: 1 0 auto;
    width: 0;
    padding: calc((#{$row-height} - #{$line-height}) / 2) 0;

    &.editable {
      cursor: pointer;

      &:hover {
        background: rgba(0,0,0, 0.05);
      }
    }

    a {
      color: #4a4a4a;
      text-decoration: underline;

      &:hover {
        color: #4a4a4a;
      }
    }
  }

  &-amount {
    flex: 0 0 72px;
    min-width: 72px;
    height: $row-height;
    display: flex;
    padding-right: 12px;
    justify-content: flex-end;
    align-items: center;

    @media(max-width: 576px) {
      flex-basis: 36px;
      min-width: 36px;
      padding-right: 8px;
    }
  }

  &-weight {
    flex: 0 0 72px;
    height: $row-height;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media(max-width: 576px) {
      flex-basis: 60px;
      margin-right: 8px;
    }
  }

  &-btn {
    flex: 0 0 $row-height;
    height: $row-height;
    width: $row-height;
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 0;
    color: #4a4a4a;
    margin-right: 8px;

    &:hover {
      background: rgba(#000, .05);
      color: #4a4a4a;
    }

    &.disabled {
      opacity: .3;
      pointer-events: none;
    }

    .svg-inline--fa.fa-w-14.fa-amazon  {
      width: 19px;
      height: 19px;
    }
  }

  .type-select-icon {
    width: 24px;
    height: 24px;
    opacity: .75;
  }

  .type-select-btn {
    &:after {
      content: none;
    }
  }

  .amount-edit-form {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    &:hover {
      background: rgba(#000, .05);
    }

    &.ediging:hover {
      background: transparent;
    }

    input {
      width: 100%;
      text-align: right;
    }
  }
}
