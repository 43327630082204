.gear-library {
  list-style: none;
  padding: 0;
  margin: 0;
}

.gear-library-item {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 10px;
  border: 1px solid #d8d8d8;
  border-bottom: none;
  cursor: pointer;
  margin-top: -1px;
  background: #fff;

  &:last-of-type {
    border-bottom: 1px solid #d8d8d8;
  }

  &:hover {
    background: rgba(#000, .01);
  }

  &-left {
    flex: 1 0 auto;
    width: 0;
  }

  &-right {
    flex: 0 0 56px;
    text-align: right;
  }

  &-tag {
    background: rgba(#000, .1);
    padding: 2px;
    font-size: 13px;
    opacity: .8;
    margin-right: 4px;
  }
}

.gear-library-placeholder {
  width: 100%;
  padding: 32px 24px;
  background: #e4e4e4;
  color: rgba(#4a4a4a, .6);
  text-align: center;
  font-size: 16px;
}
