$screen-xxl: 1400px;
$screen-xl:1200px;
$screen-lg:992px;
$screen-md:768px;
$screen-sm:576px;

@mixin when-screen ($size) {
  @if $size == 'xxl' {
    @media(min-width: $screen-xxl) {
      @content;
    }
  }

  @if $size == 'xl' {
    @media(min-width: $screen-xl) and (max-width: $screen-xxl - 1) {
      @content;
    }
  }

  @else if $size == 'lg' {
    @media(min-width: $screen-lg) and (max-width: $screen-xl - 1) {
      @content;
    }
  }

  @else if $size == 'md' {
    @media(min-width: $screen-md) and (max-width: $screen-lg - 1) {
      @content;
    }
  }

  @else if $size == 'sm' {
    @media(min-width: $screen-sm) and (max-width: $screen-md - 1) {
      @content;
    }
  }

  @else if $size == 'xs' {
    @media(max-width: $screen-sm - 1) {
      @content;
    }
  }
}

@mixin under-screen ($size) {
  @if $size == 'md' {
    @media(max-width: $screen-lg - 1) {
      @content;
    }
  }

  @else if $size == 'sm' {
    @media(max-width: $screen-md - 1) {
      @content;
    }
  }
}

@mixin over-screen($size) {
  @if $size == 'sm' {
    @media(min-width: $screen-sm) {
      @content;
    }
  }
}
