.side-nav {
  position: relative;
  flex: 0;
  height: calc(100vh - 56px);
  margin-top: 56px;
  background: #fff;
  overflow: scroll;
  background: #f3f3f3;

  &.show {
    flex-basis: 320px;
  }

  .side-nav-toggle-button {
    position: absolute;
    right: -50px;
    top: 0;
  }
}
