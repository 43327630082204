.gear-edit-modal {
  .btn {
    border-radius: 0;
  }

  .modal-body {
    padding: 24px;
  }

  .form-control {
    border-radius: 0;
  }

  .delete-btn {
    margin-top: 16px;

    svg {
      margin-right: 6px;
    }
  }
}
