.settings {
  padding-top: 56px;

  .container {
    max-width: 960px;
  }

  h4 {
    font-weight: 600;
    line-height: 20px;
    margin: 48px 0 32px;
    font-size: 20px;
  }
}
