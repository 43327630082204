.gear-nav {
  padding: 32px 16px 88px;

  h4 {
    font-size: 16px;
    line-height: 48px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  input {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    font-size: 14px;
    height: 44px;
    padding: 6px 14px;
    border-radius: 0;
  }
}

.gear-nav-search {
  position: relative;
  margin-bottom: 16px;

  &-icon {
    position: absolute;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .7;
  }

  input {
    padding-left: 40px;
  }
}
