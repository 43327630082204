.pack-table {
  &-category-placeholder {
    width: 100%;
    padding: 32px 24px;
    background: #eee;
    color: rgba(#4a4a4a, .6);
    text-align: center;
    font-size: 16px;
    border: 1px solid #d8d8d8;

    span {
      display: inline-block;
      max-width: 500px;
    }
  }

  .category-header,
  .table-row,
  .table-footer
   {
    margin-top: -1px;
  }
}
